<template>
  <div class="filter-exchange">
    <div class="filter-exchange__btn-block">
      <div class="filter-exchange__btn-left">
        <div class="filter-exchange__btn-left__label">Фильтр</div>
        <div class="filter-exchange__btn-left__reset-btn" @click="resetFilter">Сбросить</div>
      </div>
      <div @click="closeLeftSidebar" class="lg:tw-hidden">
        <zem-icon class="cursor-pointer" name="close" size="18" />
      </div>
    </div>
    <div class="filter-exchange__label">Статус</div>
    <div class="filter-exchange__checkbox-block">
      <ZemCheckbox :value="status.checkbox[0]" @input="inputCheckboxStatus(0)">Все</ZemCheckbox>
      <ZemCheckbox :value="status.checkbox[1]" @input="inputCheckboxStatus(1)">Активный</ZemCheckbox>
      <ZemCheckbox :value="status.checkbox[2]" @input="inputCheckboxStatus(2)">Архивный</ZemCheckbox>
    </div>
    <div class="filter-exchange__dropdown">
      <div class="zem-dropdown mb-0">
        <label class="filter-exchange__label" for="date">Период</label>
        <date-picker
          v-model="date"
          class="w-100"
          format="DD.MM.YYYY"
          locale="ru"
          placeholder="Выбрать период"
          range
          value-type="format"
          @change="changeRangeDate('date', $event, 'status_active_at')"
        ></date-picker>
      </div>
      <div class="zem-dropdown mb-0">
        <label class="filter-exchange__label" for="date">Регионы</label>
        <v-select
          v-model="selectedRegions"
          :components="{OpenIndicator}"
          :options="$store.state.other.regions"
          label="title"
          multiple
          placeholder="Выбрать"
          @input="changeRegions($event)"
        >
          <template #open-indicator="{attributes}">
            <img :src="require('@/assets/icons/dropdown-arrow.svg')" alt="" />
          </template>
        </v-select>
      </div>
    </div>
  </div>
</template>

<script>
import ZemCheckbox from './ui/Checkbox'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import moment from 'moment'
import ExchangeService from '@/services/exchange.service'
import ZemDropdownList from './ui/ZemDropdownList'
import PeopleAndCompaniesService from '@/services/peopleAndCompanies.service'
import 'vue-select/dist/vue-select.css'
import ZemMultiSelect from '@/components/ui/ZemMultiSelect'
import ZemIcon from '@/components/ui/ZemIcon'

export default {
  components: {
    ZemCheckbox,
    DatePicker,
    ZemDropdownList,
    ZemMultiSelect,
    ZemIcon,
  },

  data() {
    return {
      selectedRegions: [],
      OpenIndicator: {
        render: createElement => createElement('span', ''),
      },
      role: {
        checkbox: [true, false, false],
      },
      status: {
        checkbox: [true, false, false],
      },
      date: 'Выбрать период',
      region: {
        id: 'NxOpZowo9GmjKqdR',
        title: 'Москва и МО',
      },
      isDatePickerVisible: false,
      vcoConfig: {
        middleware: this.middleware,
        events: ['dblclick', 'click'],
        isActive: true,
        detectIFrame: true,
        capture: false,
      },
      filtering: {},
    }
  },

  mounted() {
    ExchangeService.getFilterRegions()
    // OrdersService.getFilterStatuses()
    // OrdersService.getPartnerStatuses()
  },

  computed: {
    dateRange() {
      if (this.date === 'Выбрать период') {
        return this.date
      } else {
        return this.date[0] + ' - ' + this.date[1]
      }
    },
    isPeoples() {
      return ['peoples', 'peoples-id'].includes(this.$route.name)
    },
    storeRegions() {
      return this.$store.state.other.regions.filter(item => ['Все регионы'].includes(item.title))
    },
  },

  watch: {
    storeRegions() {
      this.selectedRegions = this.storeRegions
    },
  },

  methods: {
    changeRegions(event, useFilter = true) {
      this.handlerSelected(event, 'selectedRegions', 'region_id')
      if (useFilter) this.getFilter()
    },
    handlerSelected(e, data, search) {
      if (e.length > 0) {
        if (e[e.length - 1].id === 0) {
          this[data] = [e[e.length - 1]]
        } else if (e[0].id === 0) {
          this[data] = [e.find(item => item.id !== 0)]
        } else {
          this[data] = e
        }
      }
      this.filteringPOC(this[data], search)
    },
    filteringPOC(array, field) {
      let newArr = array.map(item => item.value)
      if (newArr.includes(0) || array.length === 0) {
        delete this.filtering[field]
      } else {
        this.filtering[field] = newArr
      }
    },
    inputCheckboxStatus(num) {
      this.status.checkbox = [false, false, false]
      this.status.checkbox[num] = true
      if (num === 0) delete this.filtering['is_active']
      if (num === 1) this.filtering['is_active'] = 1
      if (num === 2) this.filtering['is_active'] = 0

      this.getFilter()
    },
    changeRangeDate(date, event, name) {
      if (event[0] === null && event[1] === null) {
        delete this.filtering[name]
      } else {
        this.filtering[name] =
          moment(this[date][0], 'DD.MM.YYYY').format('YYYY-MM-DD') +
          '|' +
          moment(this[date][1], 'DD.MM.YYYY').format('YYYY-MM-DD')
      }
      this.getFilter()
    },
    resetFilter() {
      this.status.checkbox = [true, false, false]
      this.region = {
        title: 'Регион',
      }
      this.selectedRegions = []
      this.filtering = {}
      this.getFilter()
    },
    onSelectRange() {
      this.isDatePickerVisible = !this.isDatePickerVisible
    },
    middleware(event) {
      return event.target.className !== 'modal'
    },
    closeLeftSidebar() {
      this.$store.commit('sidebars/changeLeftSidebar', false)
    },
    getFilter() {
      let data = ''
      let counter = 0

      for (let key in this.filtering) {
        data += key + ':' + this.filtering[key]
        counter++
        if (Object.keys(this.filtering).length !== counter) data += ';'
      }

      this.$emit('filter', {
        ...data,
        counter: counter,
        status: this.status.checkbox,
      })

      if (this.isPeoples) {
        this.$store.commit('peoples/filterMain', data)
        this.$store.commit('peoples/getSearchCount', counter)
        PeopleAndCompaniesService.getAllPeoples()
      } else {
        this.$store.commit('companies/filterMain', data)
        this.$store.commit('companies/getSearchCount', counter)
        PeopleAndCompaniesService.getAllCompanies()
      }
    },
  },
}
</script>

<style lang="scss">
.vdpr-datepicker__calendar-input-wrapper {
  display: none;
}

.vdpr-datepicker {
  position: relative;
  left: 35px;
  top: -200px;
  z-index: 9;
}

.vdpr-datepicker__calendar-dialog--inline {
  position: absolute;
}

.filter-exchange {
  .mx-datepicker {
    width: 100%;
  }

  .mx-input-wrapper {
    .mx-input {
      font-weight: 400;
      font-size: 12px;
      line-height: 13px;
      padding: 6px 6px 6px 8px;
      height: unset;
      border: 1px solid #f0f0f0;
      -webkit-box-shadow: unset;
      box-shadow: unset;
    }

    .mx-input:hover,
    .mx-input:focus {
      border-color: $color-accent;
    }

    .mx-input::-webkit-input-placeholder {
      color: #9ba6b3;
    }

    .mx-input::-moz-placeholder {
      color: #9ba6b3;
    }

    .mx-icon-calendar {
      color: #9ba6b3;
      font-size: 13px;
    }
  }
}
</style>

<style lang="scss" scoped>
.filter-exchange {
  display: block;
  padding: 32px 24px;
  min-width: 192px;

  &__btn-block {
    display: flex;
    justify-content: space-between;
  }

  &__btn-left {
    display: flex;
    align-items: center;

    &__label {
      font-weight: 700;
      font-size: 12px;
      line-height: 13px;
      color: $color-mine-shaft;
    }

    &__reset-btn {
      font-weight: 600;
      font-size: 12px;
      line-height: 13px;
      color: $color-accent;
      text-align: right;
      flex: 1;
      margin-left: 8px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__label {
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    color: $color-gull-gray;
    text-align: left;
    margin-top: 16px;
  }

  &__checkbox-block {
    margin-top: 8px;

    label:not(:first-child) {
      margin-top: 8px;
    }
  }

  &__period-block {
    display: flex;
    margin-top: 24px;
    margin-bottom: 8px;
  }

  &__btn-period {
    width: 100%;
    padding: 5px 6px 5px 8px;
    border: 1px solid $color-catskill-grey;
    border-radius: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    cursor: pointer;
    background-color: $color-white;
    color: $color-gull-gray;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      background: $color-iron;
      border: 1px solid $color-iron;
      //color: $color-oslo-gray;
      opacity: 0.8;
    }
  }

  &__icon-period {
    width: 16px;
    height: 16px;
    background-image: url('../assets/icons/calendar.svg');
  }

  &__dropdown {
    margin-top: 8px;
  }
}

@media screen and (max-width: 1440px) {
  .filter-exchange {
    padding: 20px;
    min-width: 152px;
  }
}
</style>
