<template>
  <div>
    <SidebarLeft :class="{'sidebar-left-show': sidebars.leftSidebar}">
      <PeopleAndCompaniesForm v-if="isLeftSidebarVisible" ref="filter" @update-role="selectedItem.name = $event" />
    </SidebarLeft>

    <TableContainer
      v-if="['peoples', 'peoples-id'].includes($route.name)"
      ref="table-container-peoples"
      :columns="tableDataPeoples"
      title-add-button="Контакт"
      :counter-filter="$store.state.peoples.searchCount"
      :is-archive="$can('edit', 'human-read-archive')"
      :is-archiving="$can('edit', 'human-trash')"
      :is-loading-items="$store.state.other.isLoadingItems"
      :is-loading-new-item="$store.state.other.isLoadingNewItem"
      :is-remove-from-archive="$can('edit', 'human-delete')"
      :rows="$store.state.peoples.peoples"
      :show-add-button="$can('edit', 'human-create')"
      :sorting-fields="sortingFieldsPeoples"
      is-archive
      name="peoples"
      title="Контрагенты | Контакты"
      @show-right-sidebar="showRightSidebar"
      @on-selected="selectItem"
      @update-data="updateData"
      @archive-items="archiveItems('peoples')"
      @delete-item="deleteItem('peoples')"
      @return-from-archive="returnFromArchive('peoples')"
    />

    <TableContainer
      v-else-if="['organizations', 'organizations-id'].includes($route.name)"
      ref="table-container-companies"
      :columns="tableDataCompanies"
      title-add-button="Организация"
      :counter-filter="$store.state.companies.searchCount"
      :is-archive="$can('edit', 'organization-read-archive')"
      :is-archiving="$can('edit', 'organization-trash')"
      :is-loading-items="$store.state.other.isLoadingItems"
      :is-loading-new-item="$store.state.other.isLoadingNewItem"
      :is-remove-from-archive="$can('edit', 'organization-delete')"
      :rows="$store.state.companies.companies"
      :show-add-button="$can('edit', 'organization-create')"
      :sorting-fields="sortingFieldsCompanies"
      is-archive
      name="companies"
      title="Контрагенты | Организации"
      @show-right-sidebar="showRightSidebar"
      @on-selected="selectItem"
      @update-data="updateData"
      @delete-item="deleteItem('companies')"
      @archive-items="archiveItems('companies')"
      @return-from-archive="returnFromArchive('companies')"
    />

    <SidebarRight :class="{'sidebar-right--show': sidebars.rightSidebar}">
      <template v-if="$route.params.id && selectedRowsCompanies.length < 2 && selectedRowsPeoples.length < 2">
        <DetailsPeople v-if="isPeoples" ref="people" />
        <DetailsCompanies v-else ref="company" />
      </template>

      <div v-if="newItem" class="sidebar__container">
        <NewPeopleOrCompany :type="selectedItem.name" @create-item="showRightSidebar" />
      </div>

      <DetailsFilter
        v-if="selectedRowsPeoples.length > 1 && isPeoples"
        :count="selectedRowsPeoples.length"
        :fields="fieldsPeoples"
      />

      <DetailsFilter
        v-if="selectedRowsCompanies.length > 1 && !isPeoples"
        :count="selectedRowsCompanies.length"
        :fields="fieldsCompanies"
      />
    </SidebarRight>
  </div>
</template>

<script>
import SidebarLeft from '../components/SidebarLeft'
import SidebarRight from '../components/SidebarRight'
import UserCard from '../components/UserCard'
import CollapseCard from '../components/CollapseCard'
import peoplesAndCompanies from '@/assets/peoplesAndCompanies.js'
import PeopleAndCompaniesForm from '@/components/FilterPeopleAndCompaniesForm'
import PeopleAndCompaniesService from '@/services/peopleAndCompanies.service'
import TablePeoples from '@/components/TablePeoples'
import TableCompanies from '@/components/TableCompanies'
import NewPeopleOrCompany from '@/components/NewPeopleOrCompany'
import DetailsFilter from '@/components/DetailsFilter'
import {errorHandler, formattedPhone, phoneTrim} from '@/assets/scripts/scripts'
import UserService from '@/services/user.service'
import moment from 'moment'
import TableContainer from '@/components/TableContainer.vue'
import ProfileService from '@/services/profile.service'
import {eventBus} from '@/main'
import DetailsPeople from '@/components/DetailsPeople.vue'
import DetailsCompanies from '@/components/DetailsCompanies.vue'

export default {
  name: 'PeopleAndCompanies',
  components: {
    DetailsCompanies,
    DetailsPeople,
    TableContainer,
    SidebarLeft,
    TablePeoples,
    TableCompanies,
    SidebarRight,
    UserCard,
    CollapseCard,
    PeopleAndCompaniesForm,
    NewPeopleOrCompany,
    DetailsFilter,
  },
  data() {
    return {
      isLeftSidebarVisible: true,
      isRightSidebarVisible: true,
      selectedItem: {
        name: '',
      },
      selectors: [
        {
          name: 'Контакты',
          value: '123 000',
        },
        {
          name: 'Организации',
          value: '20',
        },
      ],
      newItem: false,
      itemInfo: {},
      fields: [],
      currentId: '',
      count: 0,
      editPeople: false,
      editCompany: false,
      sortingFieldsPeoples: ['priority_organization_id'],
      sortingFieldsCompanies: [],
      tableDataPeoples: [],
      tableDataCompanies: [],
    }
  },

  created() {
    this.tableDataPeoples = [
      {
        label: 'ID',
        field: 'number',
        sortField: 'id',
        sort: 0,
        showInTable: true,
      },
      {
        label: 'ФИО',
        field: 'fio',
        sortField: 'human_id',
        sort: 0,
        showInTable: true,
      },
      {
        label: 'Организация',
        field: 'priority_organization',
        sortField: 'priority_organization_id',
        sort: 0,
        showInTable: true,
      },
      {
        label: 'Телефон',
        field: 'phone',
        sortField: 'phone',
        sort: 0,
        showInTable: true,
      },
    ]
    this.tableDataCompanies = [
      {
        label: 'ID',
        field: 'number',
        sortField: 'id',
        sort: 0,
        showInTable: true,
      },
      {
        label: 'Организация',
        field: 'title',
        sortField: 'priority_organization_id',
        sort: 0,
        showInTable: true,
      },
      {
        label: 'Телефон',
        field: 'phone',
        sortField: 'phone',
        sort: 0,
        showInTable: true,
      },
    ]
  },

  mounted() {
    this.getData()
  },

  computed: {
    sidebars() {
      return this.$store.state.sidebars
    },
    role() {
      return this.$store.state.user.role
    },
    theRoute() {
      return this.$route
    },
    isPeoples() {
      return ['peoples', 'peoples-id'].includes(this.$route.name)
    },
    pplNCmp() {
      return peoplesAndCompanies
    },
    selectedRowsPeoples() {
      return this.$store.state.peoples.selectedItemsForDelete
    },
    selectedRowsCompanies() {
      return this.$store.state.companies.selectedItemsForDelete
    },
    fieldsPeoples() {
      let arr = []
      if (this.$store.state.peoples.selectedPeoples !== undefined) {
        this.$store.state.peoples.selectedPeoples.forEach(item => {
          arr.push({
            title: 'Человек ' + item.number,
            data: [
              {
                name: 'Название',
                value: item.name,
                new: false,
                read: true,
                type: 'text',
              },
              {
                name: 'ФИО',
                value: item.fio,
                new: false,
                read: true,
                type: 'text',
              },
              {
                name: 'Телефон',
                value: formattedPhone(item.phone),
                new: false,
                read: true,
                type: 'phone',
              },
              {
                name: 'Email',
                value: item.email,
                new: false,
                read: true,
                type: 'text',
              },
            ],
          })
        })
      }
      return arr
    },
    fieldsCompanies() {
      const {selectedCompanies} = this.$store.state.companies
      if (selectedCompanies !== undefined) {
        return selectedCompanies.map(item => ({
          title: 'Организация ' + item.number,
          data: [
            {
              name: 'Название',
              value: item.title,
              new: false,
              read: true,
              type: 'text',
            },
            {
              name: 'ИНН',
              value: item.inn,
              new: false,
              read: true,
              type: 'text',
            },
            {
              name: 'Телефон',
              value: formattedPhone(item.phone),
              new: false,
              read: true,
              type: 'phone',
            },
          ],
        }))
      }
    },
  },

  watch: {
    theRoute() {
      if (['peoples', 'organizations'].includes(this.$route.name)) this.getData()
    },
    selectedRowsPeoples() {
      PeopleAndCompaniesService.getSelectedPeoples()
    },
    selectedRowsCompanies() {
      PeopleAndCompaniesService.getSelectedCompanies()
    },
  },

  methods: {
    async getData() {
      const {id} = this.$route.params
      this.$store.commit('sidebars/changeLeftSidebar', true)
      this.$store.commit('sidebars/changeRightSidebar', false)
      if (this.isPeoples) {
        await PeopleAndCompaniesService.getAllPeoples()
      } else {
        await PeopleAndCompaniesService.getAllCompanies()
      }
      this.selectedItem.name = this.isPeoples ? 'Контакты' : 'Организации'
      const typeName = this.isPeoples ? 'peoples' : 'companies'
      if (id) {
        this.$store.commit(`${typeName}/itemsForDelete`, [id])
        this.$store.commit('sidebars/changeRightSidebar', true)
      }
      ProfileService.getOwnershipList().then(r => {
        this.$store.commit('profile/getOwnership', r.data.data)
      })
    },
    async selectItem(data) {
      if (data.column.field !== 'checkbox') {
        this.isNew = false
        this.isEdit = false
        this.isSettings = false
        this.$store.commit('sidebars/changeRightSidebar', !this.$store.state.orders.isArchive)
        const {id} = this.$route.params
        if (id !== data.row.id) {
          await this.$router.push({
            name: `${this.isPeoples ? 'peoples' : 'organizations'}-id`,
            params: {
              id: data.row.id,
            },
          })
          if (this.isPeoples) {
            await this.$refs.people.getPeople()
          } else {
            await this.$refs.company.getCompany()
          }
        }
      }
    },
    showRightSidebar() {
      this.newItem = true
      this.$store.commit('sidebars/changeRightSidebar', true)
      this.itemInfo = {}
    },
    updateData() {
      if (this.isPeoples) {
        PeopleAndCompaniesService.getAllPeoples()
      } else {
        PeopleAndCompaniesService.getAllCompanies()
      }
    },
    async returnFromArchive(type) {
      await PeopleAndCompaniesService.restoreItems(type).then(r => {
        this.$toast.success(r.data.message)
      })
      await PeopleAndCompaniesService.getAllCompanies()
      await PeopleAndCompaniesService.getAllPeoples()
    },
    deleteItem(type) {
      if (type === 'peoples') {
        this.$refs[`table-container-peoples`].loadingDelete = true
      } else {
        this.$refs[`table-container-companies`].loadingDelete = true
      }
      PeopleAndCompaniesService.deleteItems(true, type)
        .then(r => {
          this.$toast.success(r.data.message)
          if (type === 'peoples') {
            this.$refs[`table-container-peoples`].loadingDelete = false
            this.$refs[`table-container-peoples`].showDeleteModal = false
          } else {
            this.$refs[`table-container-companies`].loadingDelete = false
            this.$refs[`table-container-companies`].showDeleteModal = false
          }
          this.$store.commit('sidebars/changeRightSidebar', false)
          this.$store.commit('peoples/itemsForDelete', [])
          this.$store.commit('companies/itemsForDelete', [])
          eventBus.$emit('new-state', false)
          PeopleAndCompaniesService.getAllPeoples()
          PeopleAndCompaniesService.getAllCompanies()
        })
        .catch(e => {
          errorHandler(e)
        })
    },
    archiveItems(role) {
      this.$store.commit('sidebars/changeRightSidebar', false)
      this.$store.state[role].selectedItemsForDelete.forEach((item, index, arr) => {
        if (index + 1 !== arr.length) {
          PeopleAndCompaniesService.deleteItems(false, role)
        } else {
          PeopleAndCompaniesService.deleteItems(false, role).then(r => {
            this.$toast.success(r.data['message'])
            role === 'peoples' ? PeopleAndCompaniesService.getAllPeoples() : PeopleAndCompaniesService.getAllCompanies()
          })
        }
      })
    },
  },
  beforeDestroy() {
    this.$store.commit('peoples/getAllPeoples', {
      data: [],
      meta: {
        custom: {
          total: null,
        },
        pagination: {
          current_page: null,
          total_pages: null,
          total: null,
        },
      },
    })
    this.$store.commit('companies/getAllCompanies', {
      data: [],
      meta: {
        custom: {
          total: null,
        },
        pagination: {
          current_page: null,
          total_pages: null,
          total: null,
        },
      },
    })
  },
}
</script>

<style lang="scss" scoped>
.sidebar {
  &__container {
    margin: 22px 22px;
  }
}

@media screen and (max-width: 1024px) {
  .sidebar {
    &__container {
      width: 100%;
    }
  }
}
</style>
