<template>
  <div class="tw-w-full tw-h-[calc(100%-45px)] md:tw-h-full">
    <div v-if="element" class="sidebar-right-container position-relative tw-overflow-y-auto tw-h-full tw-w-full tw-p-4">
      <zem-card class="tw-flex-col">
        <section class="tw-w-full tw-flex tw-justify-between tw-items-start">
          <div>
            <zem-card-title>{{ element.title }}</zem-card-title>
            <div class="tw-flex tw-items-center tw-text-xs tw-text-gray-700">
              <span class="tw-w-1.5 tw-h-1.5 tw-bg-[#0B9999] tw-rounded-full tw-mr-1"></span>
              ID {{ element.number }}
            </div>
            <div class="tw-flex tw-items-center tw-text-xs tw-text-gray-700">
              <span class="tw-w-1.5 tw-h-1.5 tw-bg-[#0EC6C6] tw-rounded-full tw-mr-1"></span>
              ID {{ element.id }}
            </div>
          </div>
          <div class="tw-flex tw-items-center tw-gap-2">
            <template v-if="!newElement && !editElement">
              <Edit3Icon
                v-if="isShowEditButton && $can('edit', 'organization-update')"
                class="text-primary tw-cursor-pointer"
                size="16"
                @click="editElement = true"
              />
              <XIcon class="text-primary tw-cursor-pointer" size="18" @click="closeRightSidebar" />
            </template>
            <template v-else-if="!createItemLoading">
              <zem-link class="mr-2 mobile-d-none" @click="editElement = false">Отменить</zem-link>
              <zem-button class="mobile-d-none" @click="saveData">Сохранить</zem-button>
            </template>

            <zem-button v-if="createItemLoading" disabled>
              <zem-preloader color="light" />
            </zem-button>
          </div>
        </section>
        <slot name="bottom"></slot>
      </zem-card>
      <ZemCollapse is-opened-default title="Контакты">
        <div class="zem-collapse-table rows">
          <div class="zem-collapse-table__row tw-grid-cols-[1fr_1fr] md:tw-grid-cols-[1fr_2fr]">
            <div class="zem-collapse-table__column title">Дата создания</div>
            <div class="zem-collapse-table__column text">
              {{ formatDateTime(element.created_at) }}
            </div>
          </div>
          <div class="zem-collapse-table__row tw-grid-cols-[1fr_1fr] md:tw-grid-cols-[1fr_2fr]">
            <div class="zem-collapse-table__column title">ИНН</div>
            <div class="zem-collapse-table__column text">
              <template v-if="!editElement">{{ element.inn }}</template>
              <ZemInput v-else v-model="element.inn" />
            </div>
          </div>
          <div class="zem-collapse-table__row tw-grid-cols-[1fr_1fr] md:tw-grid-cols-[1fr_2fr]">
            <div class="zem-collapse-table__column title">Название</div>
            <div class="zem-collapse-table__column text">
              <template v-if="!editElement">{{ element.title }}</template>
              <ZemInput v-else v-model="element.title" />
            </div>
          </div>
          <div class="zem-collapse-table__row tw-grid-cols-[1fr_1fr] md:tw-grid-cols-[1fr_2fr]">
            <div class="zem-collapse-table__column title">Тип организации</div>
            <div class="zem-collapse-table__column text overflow-unset">
              <template v-if="!editElement">{{ element.organization_ownership.data.title }}</template>
              <ZemDropdownList
                v-else
                :default="'Тип организации'"
                :options="$store.state.profile.ownership"
                :value="element.organization_ownership.data"
                class="select m-0"
                item="name"
                placeholder="Выбрать"
                @input="element.organization_ownership.data = $event"
              />
            </div>
          </div>
        </div>
      </ZemCollapse>
    </div>

    <preloader-wrapper :show="$store.state.other.isLoadingItem" />
  </div>
</template>

<script>
import PeopleAndCompaniesService from '@/services/peopleAndCompanies.service'
import CollapseCard from '@/components/CollapseCard.vue'
import ZemPreloader from '@/components/ui/ZemPreloader.vue'
import ZemCard from '@/components/ui/ZemCard.vue'
import ZemLink from '@/components/ui/ZemLink.vue'
import ZemCardTitle from '@/components/ui/ZemCardTitle.vue'
import ZemButton from '@/components/ui/ZemButton.vue'
import {formattedPhone} from '@/assets/scripts/scripts'
import ZemCollapse from '@/components/ui/ZemCollapse.vue'
import {Edit3Icon, XIcon} from 'vue-feather-icons'
import PreloaderWrapper from '@/components/PreloaderWrapper.vue'
import moment from 'moment/moment'
import ZemDropdownList from '@/components/ui/ZemDropdownList.vue'
import ZemInput from '@/components/ui/ZemInput.vue'

export default {
  components: {
    ZemInput,
    ZemDropdownList,
    PreloaderWrapper,
    ZemCollapse,
    ZemButton,
    ZemCardTitle,
    ZemLink,
    ZemCard,
    ZemPreloader,
    CollapseCard,
    XIcon,
    Edit3Icon,
  },
  data() {
    return {
      editElement: false,
      newElement: false,
      isShowEditButton: true,
      elementData: null,
      title: '',
      id: '',
      element: null,
      object: null,
      fields: [],
      organization: null,
      createItemLoading: false,
    }
  },
  mounted() {
    this.getCompany()
  },
  methods: {
    formattedPhone,
    getCompany() {
      this.$store.commit('other/onChangeLoadingItem', true)
      const {id} = this.$route.params
      PeopleAndCompaniesService.getCompany(id).then(r => {
        this.element = r.data.data

        this.$store.commit('other/onChangeLoadingItem', false)
      })
    },
    saveData() {
      this.createItemLoading = true
      const {id} = this.$route.params
      const {inn, title} = this.element
      PeopleAndCompaniesService.updateCompany(
        id,
        {
          inn,
          title,
          organization_ownership_id: this.element.organization_ownership.data.value,
        },
        true
      ).then(() => {
        this.createItemLoading = false
        this.editElement = false
        this.getCompany()
      })
    },
    formatDateTime(utc) {
      return moment(utc, 'X').format('DD.MM.YYYY HH:mm')
    },
    closeRightSidebar() {
      this.editElement = false
      this.newElement = false
      this.$router.push({name: 'organizations'})
      this.$emit('on-close')
      this.$store.commit('sidebars/changeRightSidebar', false)
    },
  },
}
</script>

<style lang="scss" scoped></style>
