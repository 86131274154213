<template>
  <div>
    <div class="new-pnc__header">
      <h4 v-if="type === 'Контакты'">Новый контакт</h4>
      <h4 v-else>Новая организация</h4>
      <div v-if="!$store.state.profile.companiesSearch.length > 0" class="new-pnc__header-block">
        <zem-link v-if="role === 'admin' || role === 'front-manager'" @click="cancelCreated"
          >{{ $t('Cancel') }}
        </zem-link>
        <zem-button v-if="role === 'admin' || role === 'front-manager'" @click="create">
          <img :src="require('@/assets/icons/check.svg')" alt="" class="new-pnc__header__icon" />
          {{ $t('Save') }}
        </zem-button>
      </div>
    </div>
    <div v-if="type === 'Контакты'">
      <validation-observer ref="peopleForm">
        <div v-for="(item, idx) in newPeopleFields" :key="idx">
          <div class="new-pnc__row">
            <div class="new-pnc__column title required-field">{{ item.name }}</div>
            <div v-if="item.name === 'Телефон'" class="new-pnc__input">
              <ValidationProvider
                v-slot="{errors}"
                :rules="{
                  required: true,
                  regex: `^[\\+]?[7]?[8]?\\s[(][0-9]{3}[)]\\s[0-9]{3}[-\\s\\.]?[0-9]{2}[-\\s\\.]?[0-9]{2}$`,
                }"
                class="w-100"
                name="Телефон"
              >
                <ZemInput v-model="item.value" :placeholder="item.name" class="m-0" isCompact mask="phone" type="tel" />
                <p v-show="errors.length !== 0" class="details-form__data-block__error">
                  {{ errors[0] }}
                </p>
              </ValidationProvider>
            </div>
            <div v-else-if="item.name === 'Название'" class="new-pnc__input">
              <ValidationProvider v-slot="{errors}" :rules="{required: true}" class="w-100" name="Название">
                <ZemInput v-model="item.value" :placeholder="item.name" class="m-0" isCompact type="text" />
                <p v-show="errors.length !== 0" class="details-form__data-block__error">
                  {{ errors[0] }}
                </p>
              </ValidationProvider>
            </div>
            <div v-else-if="item.name === 'ФИО'" class="new-pnc__input">
              <ValidationProvider v-slot="{errors}" :rules="{required: true}" class="w-100" name="ФИО">
                <ZemInput v-model="item.value" :placeholder="item.name" class="m-0" isCompact type="text" />
                <p v-show="errors.length !== 0" class="details-form__data-block__error">
                  {{ errors[0] }}
                </p>
              </ValidationProvider>
            </div>
            <div v-else-if="item.name === 'Email'" class="new-pnc__input">
              <ValidationProvider v-slot="{errors}" :rules="{required: true, email: true}" class="w-100" name="Email">
                <ZemInput v-model="item.value" :placeholder="item.name" class="m-0" isCompact type="text" />
                <p v-show="errors.length !== 0" class="details-form__data-block__error">
                  {{ errors[0] }}
                </p>
              </ValidationProvider>
            </div>
            <div v-else-if="item.name === 'Страна'" class="new-pnc__input">
              <ZemDropdownList
                :default="'Страна'"
                :options="$store.state.profile.countries"
                :placeholder="'Страна'"
                :value="item.value"
                class="select m-0"
                item="name"
                @input="item.value = $event"
              />
            </div>
            <div v-else-if="item.name === 'Регион'" class="new-pnc__input">
              <ZemDropdownList
                :default="'Регион'"
                :options="$store.state.profile.regions"
                :placeholder="'Регион'"
                :value="item.value"
                class="select m-0"
                item="name"
                @input="item.value = $event"
              />
            </div>
            <div v-else-if="item.name === 'Город'" class="new-pnc__input">
              <ZemDropdownList
                :default="'Город'"
                :options="$store.state.profile.cities"
                :placeholder="'Город'"
                :value="item.value"
                class="select m-0"
                item="name"
                @input="item.value = $event"
              />
            </div>
            <div v-else class="new-pnc__input">
              <ZemInput v-model="item.value" :placeholder="item.name" class="m-0" isCompact type="text" />
            </div>
          </div>
        </div>
      </validation-observer>
    </div>
    <div v-show="type === 'Организации'">
      <validation-observer ref="companyForm">
        <div v-for="(item, idx) in newCompanyFields" :key="idx">
          <div v-if="$store.state.profile.companiesSearch.length === 0 || item.name === 'ИНН'" class="new-pnc__row">
            <div class="new-pnc__column title required-field">{{ item.name }}</div>
            <div v-if="item.name === 'ИНН'" class="new-pnc__input">
              <ValidationProvider v-slot="{errors}" :rules="{required: true}" class="w-100" name="ИНН">
                <ZemInput v-model="item.value" :placeholder="item.name" class="m-0" isCompact type="text" />
                <p v-show="errors.length !== 0" class="details-form__data-block__error">
                  {{ errors[0] }}
                </p>
              </ValidationProvider>
            </div>
            <div v-else-if="item.name === 'Название'" class="new-pnc__input">
              <ValidationProvider v-slot="{errors}" :rules="{required: true}" class="w-100" name="Название">
                <ZemInput v-model="item.value" :placeholder="item.name" class="m-0" isCompact type="text" />
                <p v-show="errors.length !== 0" class="details-form__data-block__error">
                  {{ errors[0] }}
                </p>
              </ValidationProvider>
            </div>
            <div v-else-if="item.name === 'Страна'" class="new-pnc__input">
              <ValidationProvider v-slot="{errors}" :rules="{required: true}" class="w-100" name="Страна">
                <ZemDropdownList
                  :default="'Страна'"
                  :options="$store.state.profile.countries"
                  :placeholder="'Страна'"
                  :value="item.value"
                  class="select m-0"
                  item="name"
                  @input="item.value = $event"
                />
                <p v-show="errors.length !== 0" class="details-form__data-block__error">
                  {{ errors[0] }}
                </p>
              </ValidationProvider>
            </div>
            <div v-else-if="item.name === 'Регион'" class="new-pnc__input">
              <ValidationProvider v-slot="{errors}" :rules="{required: true}" class="w-100" name="Регион">
                <ZemDropdownList
                  :default="'Регион'"
                  :options="$store.state.profile.regions"
                  :placeholder="'Регион'"
                  :value="item.value"
                  class="select m-0"
                  item="name"
                  @input="item.value = $event"
                />
                <p v-show="errors.length !== 0" class="details-form__data-block__error">
                  {{ errors[0] }}
                </p>
              </ValidationProvider>
            </div>
            <div v-else-if="item.name === 'Город'" class="new-pnc__input">
              <ValidationProvider v-slot="{errors}" :rules="{required: true}" class="w-100" name="Город">
                <ZemDropdownList
                  :default="'Город'"
                  :options="$store.state.profile.cities"
                  :placeholder="'Город'"
                  :value="item.value"
                  class="select m-0"
                  item="name"
                  @input="item.value = $event"
                />
                <p v-show="errors.length !== 0" class="details-form__data-block__error">
                  {{ errors[0] }}
                </p>
              </ValidationProvider>
            </div>
            <div v-else-if="item.name === 'Тип организации'" class="new-pnc__input">
              <ValidationProvider v-slot="{errors}" :rules="{required: true}" class="w-100" name="Тип организации">
                <ZemDropdownList
                  :default="'Тип организации'"
                  :options="$store.state.profile.ownership"
                  :placeholder="'Тип организации'"
                  :value="item.value"
                  class="select m-0"
                  item="name"
                  @input="item.value = $event"
                />
                <p v-show="errors.length !== 0" class="details-form__data-block__error">
                  {{ errors[0] }}
                </p>
              </ValidationProvider>
            </div>
            <div v-else-if="item.name === 'ИНН'" class="new-pnc__input">
              <ZemInput
                v-model="item.value"
                :placeholder="item.name"
                class="m-0"
                isCompact
                type="text"
                @input="getCompanies"
              />
              <p v-if="$store.state.profile.companiesSearch.length > 0" class="new-pnc__input__error">
                Компания уже существует.
              </p>
            </div>
            <div v-else class="new-pnc__input">
              <ZemInput v-model="item.value" :placeholder="item.name" class="m-0" isCompact type="text" />
            </div>
          </div>
        </div>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import ZemLink from '@/components/ui/ZemLink'
import ZemButton from '@/components/ui/ZemButton'
import ZemInput from '@/components/ui/ZemInput'
import ZemDropdownList from '@/components/ui/ZemDropdownList'
import ProfileService from '@/services/profile.service'
import PeopleAndCompaniesService from '@/services/peopleAndCompanies.service'
import toastMessage from '@/helpers/messages'
import {errorHandler} from '@/assets/scripts/scripts'

export default {
  components: {
    ZemLink,
    ZemButton,
    ZemInput,
    ZemDropdownList,
  },
  props: {
    type: {
      type: String,
      default: 'Контакты',
    },
  },
  data() {
    return {
      region: '',
      country: '',
      city: '',
      ownership: '',
      newCompanyFields: [],
      newPeopleFields: [],
      timer: null,
    }
  },
  computed: {
    role() {
      return this.$store.state.user.role
    },
  },
  watch: {
    type() {
      this.$refs.companyForm.reset()
      this.$refs.peopleForm.reset()
    },
  },
  mounted() {
    ProfileService.getCities()
    ProfileService.getCountries()
    ProfileService.getRegions()
    this.templatePeople()
    this.templateOrganization()
  },
  methods: {
    templateOrganization() {
      this.newCompanyFields = [
        {
          name: 'ИНН',
          value: '',
        },
        {
          name: 'Название',
          value: '',
        },
        {
          name: 'Email',
          value: '',
        },
        {
          name: 'Страна',
          value: {
            id: '',
            title: '',
          },
        },
        {
          name: 'Регион',
          value: {
            id: '',
            title: '',
          },
        },
        {
          name: 'Город',
          value: {
            id: '',
            title: '',
          },
        },
        {
          name: 'Тип организации',
          value: '',
        },
      ]
    },
    templatePeople() {
      this.newPeopleFields = [
        {
          name: 'Название',
          value: '',
        },
        {
          name: 'ФИО',
          value: '',
        },
        {
          name: 'Телефон',
          value: '',
        },
        {
          name: 'Email',
          value: '',
        },
        {
          name: 'Страна',
          value: {
            id: '',
            title: '',
          },
        },
        {
          name: 'Регион',
          value: {
            id: '',
            title: '',
          },
        },
        {
          name: 'Город',
          value: {
            id: '',
            title: '',
          },
        },
      ]
    },
    getCompanies() {
      this.$store.commit('profile/getCompaniesSearch', [])
      clearTimeout(this.timer)
      this.timer = null
      this.timer = setTimeout(() => {
        ProfileService.getCompaniesSearch(this.newCompanyFields[0].value)
      }, 500)
    },
    cancelCreated() {
      this.$store.commit('sidebars/changeRightSidebar', false)
    },
    create() {
      if (this.type === 'Организации') {
        this.$refs.companyForm.validate().then(success => {
          if (success) {
            ProfileService.createCompany({
              inn: this.newCompanyFields[0].value,
              title: this.newCompanyFields[1].value,
              email: this.newCompanyFields[2].value,
              country_id: this.newCompanyFields[3].value.id,
              region_id: this.newCompanyFields[4].value.id,
              city_id: this.newCompanyFields[5].value.id,
              organization_ownership_id: this.newCompanyFields[6].value.id,
            })
              .then(r => {
                this.$toast.success(toastMessage.createOrganization)
                this.$store.commit('sidebars/changeRightSidebar', false)
                PeopleAndCompaniesService.getAllCompanies()
                this.templateOrganization()
              })
              .catch(e => {
                this.$toast.error(JSON.parse(e.request.responseText).message)
              })
          } else {
            this.$toast.error(toastMessage.errorValidForm)
          }
        })
      } else {
        this.$refs.peopleForm.validate().then(success => {
          if (success) {
            PeopleAndCompaniesService.createPeople({
              title: this.newPeopleFields[0].value,
              fio: this.newPeopleFields[1].value,
              phone: this.newPeopleFields[2].value
                ? this.newPeopleFields[2].value
                    .replace('8 ', '+7')
                    .replace(' (', '')
                    .replace('(', '')
                    .replace(') ', '')
                    .replaceAll('-', '')
                : '',
              email: this.newPeopleFields[3].value,
              country_id: this.newPeopleFields[4].value.id,
              region_id: this.newPeopleFields[5].value.id,
              city_id: this.newPeopleFields[6].value.id,
            })
              .then(r => {
                this.$toast.success(toastMessage.createPeople)
                this.$store.commit('sidebars/changeRightSidebar', false)
                PeopleAndCompaniesService.getAllPeoples()
                this.templatePeople()
              })
              .catch(e => {
                this.$toast.error(JSON.parse(e.request.responseText).message)
                errorHandler(e)
              })
          } else {
            this.$toast.error(toastMessage.errorValidForm)
          }
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.new-pnc {
  width: 100%;
  margin-top: 12px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    &-block {
      display: flex;
      align-items: center;

      .zem-link {
        margin-right: 13px;
      }
    }

    &__icon {
      margin-right: 5px;
    }

    h4 {
      font-weight: 700;
      font-size: 17px;
      line-height: 20px;
      color: $color-mine-shaft;
      margin: 0;
    }
  }

  &__input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;

    .zem-dropdown {
      width: 100%;
    }

    &__error {
      font-size: 10px;
      color: $color-red;
      margin: 5px 0 0 0;
    }
  }

  &__column {
    flex: 1;

    &--second {
      flex: 1;
      cursor: pointer;
    }

    &--select {
      width: 244px;
      justify-content: space-between !important;
      align-items: center !important;
    }
  }

  &__row {
    display: flex;
    align-items: flex-start;
    align-self: flex-start;
    align-content: inherit;
    text-align: left;
    justify-content: flex-start;
    margin-bottom: 8px;
  }
}

.title {
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: $color-gull-gray;
  margin-right: 10px;
}

.details-form__data-block__error {
  margin-top: 6px;
  margin-bottom: 0;
  margin-left: 10px;
  font-size: 10px;
  color: $color-red;
}
</style>
