/* eslint-disable no-undef */
let peoplesAndCompanies = [
  // ------------------------
  // PEOPLE DATA BEGINS HERE
  // ------------------------
  {
    chapter: 'peopleDataTable',
    head: [
      {
        type: 'text',
        data: 'ID',
      },
      {
        type: 'text',
        data: 'Фото',
      },
      {
        type: 'text',
        data: 'ФИО',
      },
      {
        type: 'text',
        data: 'Телефон',
      },
    ],

    body: [
      [
        {
          type: 'text',
          data: '2456675',
        },
        {
          type: 'image',
          data: require('@/assets/images/user.webp'),
        },
        {
          type: 'text',
          data: 'Иванов Сергей Васильевич',
        },
        {
          type: 'text',
          data: '8 923 345 34 34',
        },
      ],

      [
        {
          type: 'text',
          data: '6457897',
        },
        {
          type: 'image',
          data: 'https://pbs.twimg.com/media/Dfu1EkEXcAEOFam.jpg',
        },
        {
          type: 'text',
          data: 'Ворошилов Александр Иванович',
        },
        {
          type: 'text',
          data: '8 912 311 22 20',
        },
      ],

      [
        {
          type: 'text',
          data: '9489489',
        },
        {
          type: 'image',
          data: 'https://pp.userapi.com/c622620/v622620367/4c9a1/3sB-um95zlc.jpg',
        },
        {
          type: 'text',
          data: 'Хлебов Тимофей Викторович',
        },
        {
          type: 'text',
          data: '8 912 232 11 10',
        },
      ],

      [
        {
          type: 'text',
          data: '9388349',
        },
        {
          type: 'image',
          data: 'https://i.flibusta.club/i/679/79/585679.jpg',
        },
        {
          type: 'text',
          data: 'Кудрявцева Алена Павловна',
        },
        {
          type: 'text',
          data: '8 902 100 34 34',
        },
      ],

      [
        {
          type: 'text',
          data: '9389834',
        },
        {
          type: 'image',
          data: 'https://pbs.twimg.com/media/FEPiA9OXMAAam1A.jpg',
        },
        {
          type: 'text',
          data: 'Рябов Михаил Алексеевич',
        },
        {
          type: 'text',
          data: '8 949 234 30 10',
        },
      ],
    ],

    cardInfo: [
      {
        type: 'people',
        name: 'Рябов Михаил Алексеевич',
        phone: '8 923 345 34 34',
        email: 'example@mail.com',
        telegram: '8 923 345 34 34',
        id: '2456675',
        status: 'Активный',
        img: require('@/assets/images/user.webp'),
        fieldsInfo: [
          {
            title: 'Паспортные данные',
            data: [
              {
                name: 'Серия и номер',
                value: '9415551011',
              },
              {
                name: 'Кем выдан',
                values: [],
                possibleValues: [
                  'МВД г.Москва',
                  'МВД г.Москва2',
                  'МВД г.Москва3',
                  'МВД г.Москва4',
                  'МВД г.Москва5',
                  'МВД г.Москва6',
                  'МВД г.Москва7',
                  'МВД г.Москва8',
                  'МВД г.Москва9',
                  'МВД г.Москва10',
                  'МВД г.Москва11',
                ],
              },
              {
                name: 'Код подразделения',
                value: '101000',
              },
              {
                name: 'ФИО',
                values: [],
                possibleValues: [
                  'Иванов Сергей Васильевич',
                  'Иванов Сергей Васильевич2',
                  'Иванов Сергей Васильевич3',
                  'Иванов Сергей Васильевич4',
                  'Иванов Сергей Васильевич5',
                ],
              },
            ],
            possibleToCreate: [
              {
                name: 'Адрес регистрации',
              },
            ],
          },

          {
            title: 'Роль физлица в заказах',
            data: [
              {
                name: 'Заказчик',
                value: '123343-R1',
              },
              {
                name: 'Исполнитель',
                value: '43443-R2',
              },
            ],
            possibleToCreate: [
              {
                name: 'Роль',
              },
              {
                name: 'Номер заказа',
              },
            ],
          },

          {
            title: 'Адрес регистрации',
            data: [
              {
                name: 'Область',
                value: 'Московская',
              },
              {
                name: 'Город',
                value: 'Москва',
              },
              {
                name: 'Улица',
                value: 'Строителей',
              },
              {
                name: 'Дом',
                value: '14',
              },
            ],
            possibleToCreate: [
              {
                name: 'Квартира',
              },
            ],
          },
        ],
      },
      {
        type: 'people',
        name: 'Ворошилов Александр Иванович',
        phone: '8 912 311 22 20',
        email: 'example@mail.com',
        telegram: '8 912 311 22 20',
        id: '6457897',
        status: 'Активный',
        img: 'https://pbs.twimg.com/media/Dfu1EkEXcAEOFam.jpg',
        fieldsInfo: [
          {
            title: 'Паспортные данные',
            data: [
              {
                name: 'Серия и номер',
                value: '9415551011',
              },
              {
                name: 'Кем выдан',
                values: [],
                possibleValues: [
                  'МВД г.Москва',
                  'МВД г.Москва2',
                  'МВД г.Москва3',
                  'МВД г.Москва4',
                  'МВД г.Москва5',
                  'МВД г.Москва6',
                  'МВД г.Москва7',
                  'МВД г.Москва8',
                  'МВД г.Москва9',
                  'МВД г.Москва10',
                  'МВД г.Москва11',
                ],
              },
              {
                name: 'Код подразделения',
                value: '101000',
              },
              {
                name: 'ФИО',
                values: [],
                possibleValues: [
                  'Ворошилов Александр Иванович',
                  'Ворошилов Александр Иванович2',
                  'Ворошилов Александр Иванович3',
                  'Ворошилов Александр Иванович4',
                  'Ворошилов Александр Иванович5',
                ],
              },
            ],
            possibleToCreate: [
              {
                name: 'Адрес регистрации',
              },
            ],
          },

          {
            title: 'Роль физлица в заказах',
            data: [
              {
                name: 'Заказчик',
                value: '123343-R1',
              },
              {
                name: 'Исполнитель',
                value: '43443-R2',
              },
            ],
            possibleToCreate: [
              {
                name: 'Роль',
              },
              {
                name: 'Номер заказа',
              },
            ],
          },

          {
            title: 'Адрес регистрации',
            data: [
              {
                name: 'Область',
                value: 'Московская',
              },
              {
                name: 'Город',
                value: 'Москва',
              },
              {
                name: 'Улица',
                value: 'Строителей',
              },
              {
                name: 'Дом',
                value: '14',
              },
            ],
            possibleToCreate: [
              {
                name: 'Квартира',
              },
            ],
          },
        ],
      },
      {
        type: 'people',
        name: 'Хлебов Тимофей Викторович',
        phone: '8 912 232 11 10',
        email: 'example@mail.com',
        telegram: '8 912 232 11 10',
        id: '9489489',
        status: 'Активный',
        img: 'https://pp.userapi.com/c622620/v622620367/4c9a1/3sB-um95zlc.jpg',
        fieldsInfo: [
          {
            title: 'Паспортные данные',
            data: [
              {
                name: 'Серия и номер',
                value: '9415551011',
              },
              {
                name: 'Кем выдан',
                values: [],
                possibleValues: [
                  'МВД г.Москва',
                  'МВД г.Москва2',
                  'МВД г.Москва3',
                  'МВД г.Москва4',
                  'МВД г.Москва5',
                  'МВД г.Москва6',
                  'МВД г.Москва7',
                  'МВД г.Москва8',
                  'МВД г.Москва9',
                  'МВД г.Москва10',
                  'МВД г.Москва11',
                ],
              },
              {
                name: 'Код подразделения',
                value: '101000',
              },
              {
                name: 'ФИО',
                values: [],
                possibleValues: [
                  'Хлебов Тимофей Викторович',
                  'Хлебов Тимофей Викторович2',
                  'Хлебов Тимофей Викторович3',
                  'Хлебов Тимофей Викторович4',
                  'Хлебов Тимофей Викторович5',
                ],
              },
            ],
            possibleToCreate: [
              {
                name: 'Адрес регистрации',
              },
            ],
          },

          {
            title: 'Роль физлица в заказах',
            data: [
              {
                name: 'Заказчик',
                value: '123343-R1',
              },
              {
                name: 'Исполнитель',
                value: '43443-R2',
              },
            ],
            possibleToCreate: [
              {
                name: 'Роль',
              },
              {
                name: 'Номер заказа',
              },
            ],
          },

          {
            title: 'Адрес регистрации',
            data: [
              {
                name: 'Область',
                value: 'Московская',
              },
              {
                name: 'Город',
                value: 'Москва',
              },
              {
                name: 'Улица',
                value: 'Строителей',
              },
              {
                name: 'Дом',
                value: '14',
              },
            ],
            possibleToCreate: [
              {
                name: 'Квартира',
              },
            ],
          },
        ],
      },
      {
        type: 'people',
        name: 'Кудрявцева Алена Павловна',
        phone: '8 902 100 34 34',
        email: 'example@mail.com',
        telegram: '8 902 100 34 34',
        id: '9388349',
        status: 'Активный',
        img: 'https://i.flibusta.club/i/679/79/585679.jpg',
        fieldsInfo: [
          {
            title: 'Паспортные данные',
            data: [
              {
                name: 'Серия и номер',
                value: '9415551011',
              },
              {
                name: 'Кем выдан',
                values: [],
                possibleValues: [
                  'МВД г.Москва',
                  'МВД г.Москва2',
                  'МВД г.Москва3',
                  'МВД г.Москва4',
                  'МВД г.Москва5',
                  'МВД г.Москва6',
                  'МВД г.Москва7',
                  'МВД г.Москва8',
                  'МВД г.Москва9',
                  'МВД г.Москва10',
                  'МВД г.Москва11',
                ],
              },
              {
                name: 'Код подразделения',
                value: '101000',
              },
              {
                name: 'ФИО',
                values: [],
                possibleValues: [
                  'Кудрявцева Алена Павловна',
                  'Кудрявцева Алена Павловна2',
                  'Кудрявцева Алена Павловна3',
                  'Кудрявцева Алена Павловна4',
                  'Кудрявцева Алена Павловна5',
                ],
              },
            ],
            possibleToCreate: [
              {
                name: 'Адрес регистрации',
              },
            ],
          },

          {
            title: 'Роль физлица в заказах',
            data: [
              {
                name: 'Заказчик',
                value: '123343-R1',
              },
              {
                name: 'Исполнитель',
                value: '43443-R2',
              },
            ],
            possibleToCreate: [
              {
                name: 'Роль',
              },
              {
                name: 'Номер заказа',
              },
            ],
          },

          {
            title: 'Адрес регистрации',
            data: [
              {
                name: 'Область',
                value: 'Московская',
              },
              {
                name: 'Город',
                value: 'Москва',
              },
              {
                name: 'Улица',
                value: 'Строителей',
              },
              {
                name: 'Дом',
                value: '14',
              },
            ],
            possibleToCreate: [
              {
                name: 'Квартира',
              },
            ],
          },
        ],
      },
      {
        type: 'people',
        name: 'Рябов Михаил Алексеевич',
        phone: '8 949 234 30 10',
        email: 'example@mail.com',
        telegram: '8 949 234 30 10',
        id: '9389834',
        status: 'Активный',
        img: 'https://pbs.twimg.com/media/FEPiA9OXMAAam1A.jpg',
        fieldsInfo: [
          {
            title: 'Паспортные данные',
            data: [
              {
                name: 'Серия и номер',
                value: '9415551011',
              },
              {
                name: 'Кем выдан',
                values: [],
                possibleValues: [
                  'МВД г.Москва',
                  'МВД г.Москва2',
                  'МВД г.Москва3',
                  'МВД г.Москва4',
                  'МВД г.Москва5',
                  'МВД г.Москва6',
                  'МВД г.Москва7',
                  'МВД г.Москва8',
                  'МВД г.Москва9',
                  'МВД г.Москва10',
                  'МВД г.Москва11',
                ],
              },
              {
                name: 'Код подразделения',
                value: '101000',
              },
              {
                name: 'ФИО',
                values: [],
                possibleValues: [
                  'Рябов Михаил Алексеевич',
                  'Рябов Михаил Алексеевич2',
                  'Рябов Михаил Алексеевич3',
                  'Рябов Михаил Алексеевич4',
                  'Рябов Михаил Алексеевич5',
                ],
              },
            ],
            possibleToCreate: [
              {
                name: 'Адрес регистрации',
              },
            ],
          },

          {
            title: 'Роль физлица в заказах',
            data: [
              {
                name: 'Заказчик',
                value: '123343-R1',
              },
              {
                name: 'Исполнитель',
                value: '43443-R2',
              },
            ],
            possibleToCreate: [
              {
                name: 'Роль',
              },
              {
                name: 'Номер заказа',
              },
            ],
          },

          {
            title: 'Адрес регистрации',
            data: [
              {
                name: 'Область',
                value: 'Московская',
              },
              {
                name: 'Город',
                value: 'Москва',
              },
              {
                name: 'Улица',
                value: 'Строителей',
              },
              {
                name: 'Дом',
                value: '14',
              },
            ],
            possibleToCreate: [
              {
                name: 'Квартира',
              },
            ],
          },
        ],
      },
    ],
  },
  // ---------------------------
  // COMPANIES DATA BEGINS HERE
  // ---------------------------
  {
    chapter: 'companiesDataTable',
    head: [
      {
        type: 'text',
        data: 'ID',
      },
      {
        type: 'text',
        data: 'Наименование',
      },
      {
        type: 'text',
        data: 'Телефон',
      },
    ],
    body: [
      [
        {
          type: 'text',
          data: '7434386',
        },
        {
          type: 'text',
          data: 'Спецмонтаж',
        },
        {
          type: 'text',
          data: '8 923 345 34 34',
        },
      ],
      [
        {
          type: 'text',
          data: '1489870',
        },
        {
          type: 'text',
          data: 'Транстехсервис',
        },
        {
          type: 'text',
          data: '8 923 345 34 34',
        },
      ],
      [
        {
          type: 'text',
          data: '7512874',
        },
        {
          type: 'text',
          data: 'Формспецстрой',
        },
        {
          type: 'text',
          data: '8 923 345 34 34',
        },
      ],
      [
        {
          type: 'text',
          data: '2872376',
        },
        {
          type: 'text',
          data: 'Никельтехнострой',
        },
        {
          type: 'text',
          data: '8 923 345 34 34',
        },
      ],
      [
        {
          type: 'text',
          data: '4561606',
        },
        {
          type: 'text',
          data: 'Формпресстрой',
        },
        {
          type: 'text',
          data: '8 923 345 34 34',
        },
      ],
      [
        {
          type: 'text',
          data: '5419853',
        },
        {
          type: 'text',
          data: 'Транстехсервис',
        },
        {
          type: 'text',
          data: '8 923 345 34 34',
        },
      ],
      [
        {
          type: 'text',
          data: '9571180',
        },
        {
          type: 'text',
          data: 'Госстройпро',
        },
        {
          type: 'text',
          data: '8 923 345 34 34',
        },
      ],
      [
        {
          type: 'text',
          data: '4250844',
        },
        {
          type: 'text',
          data: 'Градстройтех',
        },
        {
          type: 'text',
          data: '8 923 345 34 34',
        },
      ],
      [
        {
          type: 'text',
          data: '2236738',
        },
        {
          type: 'text',
          data: 'Сервисбилдинг',
        },
        {
          type: 'text',
          data: '8 923 345 34 34',
        },
      ],
      [
        {
          type: 'text',
          data: '1168431',
        },
        {
          type: 'text',
          data: 'Транскорпстрой',
        },
        {
          type: 'text',
          data: '8 923 345 34 34',
        },
      ],
    ],
    cardInfo: [
      {
        type: 'company',
        phone2: '8 912 241 00 10',
        phone1: '8 923 345 34 34',
        email: 'Спецмонтаж@mail.com',
        telegram: '8 923 345 34 34',
        id: '7434386',
        status: 'Активный',
        companyType: 'ООО',
        name: 'Спецмонтаж',
        fieldsInfo: [
          {
            title: 'Сотрудники организации',
            data: [
              {
                name: 'Ген директор',
                value: 'Иванов Сергей Петрович',
              },
              {
                name: 'Главбух',
                value: 'Шабаева Мария Петровна',
              },
              {
                name: 'Менеджер',
                value: 'Зубов Алексей Николаевич',
              },
            ],
            possibleToCreate: [
              {
                name: 'Должность',
              },
              {
                name: 'Физ лицо',
              },
            ],
          },

          {
            title: 'Роли организации в заказах',
            data: [
              {
                name: 'Заказчик',
                value: '153343-R1',
              },
              {
                name: 'Исполнитель',
                value: 'Спецмонтаж-R2',
              },
            ],
            possibleToCreate: [
              {
                name: 'Роль',
                possibleValues: [
                  'я тут главная роль',
                  'а я роль чуть поменьше',
                  'моя роль - быть ролью',
                  'не доскажете, где здесб библиотека? а то мне нужно растянуть этот текст, чтобы он был очень длинным для теста...',
                ],
              },
              {
                name: 'Номер заказа',
              },
            ],
          },

          {
            title: 'Юридический адрес',
            data: [
              {
                name: 'Область',
                value: 'Московская',
              },
              {
                name: 'Город',
                value: 'Москва',
              },
              {
                name: 'Улица',
                value: 'Строителей',
              },
              {
                name: 'Дом',
                value: '14',
              },
            ],
            possibleToCreate: [
              {
                name: 'Офис',
              },
            ],
          },

          {
            title: 'Подписант от лица организации',
            data: [
              {
                name: 'Должность',
                value: 'Генеральный директор',
              },
              {
                name: 'Основание',
                value: 'Устав',
              },
            ],
            possibleToCreate: [
              {
                name: 'Дата вступления',
              },
            ],
          },
        ],
      },
      {
        type: 'company',
        phone2: '8 912 241 00 10',
        phone1: '8 923 345 34 34',
        email: 'example@mail.com',
        telegram: '8 923 345 34 34',
        id: '1489870',
        status: 'Активный',
        companyType: 'ООО',
        name: 'Транстехсервис',
        fieldsInfo: [
          {
            title: 'Сотрудники организации',
            data: [
              {
                name: 'Ген директор',
                value: 'Иванов Сергей Петрович',
              },
              {
                name: 'Главбух',
                value: 'Шабаева Мария Петровна',
              },
              {
                name: 'Менеджер',
                value: 'Зубов Алексей Николаевич',
              },
            ],
            possibleToCreate: [
              {
                name: 'Должность',
              },
              {
                name: 'Физ лицо',
              },
            ],
          },

          {
            title: 'Роли организации в заказах',
            data: [
              {
                name: 'Заказчик',
                value: 'Транстехсервис-R1',
              },
              {
                name: 'Исполнитель',
                value: '43543-R2',
              },
            ],
            possibleToCreate: [
              {
                name: 'Роль',
                possibleValues: [
                  'я тут главная роль',
                  'а я роль чуть поменьше',
                  'моя роль - быть ролью',
                  'не доскажете, где здесб библиотека? а то мне нужно растянуть этот текст, чтобы он был очень длинным для теста...',
                ],
              },
              {
                name: 'Номер заказа',
              },
            ],
          },

          {
            title: 'Юридический адрес',
            data: [
              {
                name: 'Область',
                value: 'Московская',
              },
              {
                name: 'Город',
                value: 'Москва',
              },
              {
                name: 'Улица',
                value: 'Строителей',
              },
              {
                name: 'Дом',
                value: '14',
              },
            ],
            possibleToCreate: [
              {
                name: 'Офис',
              },
            ],
          },

          {
            title: 'Подписант от лица организации',
            data: [
              {
                name: 'Должность',
                value: 'Генеральный директор',
              },
              {
                name: 'Основание',
                value: 'Устав',
              },
            ],
            possibleToCreate: [
              {
                name: 'Дата вступления',
              },
            ],
          },
        ],
      },
      {
        type: 'company',
        phone2: '8 912 241 00 10',
        phone1: '8 912 123 45 67',
        email: 'example@mail.com',
        telegram: '8 912 123 45 67',
        id: '7512874',
        status: 'Активный',
        companyType: 'ООО',
        name: 'Формспецстрой',
        fieldsInfo: [
          {
            title: 'Сотрудники организации',
            data: [
              {
                name: 'Ген директор',
                value: 'Иванов Сергей Петрович',
              },
              {
                name: 'Главбух',
                value: 'Шабаева Мария Петровна',
              },
              {
                name: 'Менеджер',
                value: 'Зубов Алексей Николаевич',
              },
            ],
            possibleToCreate: [
              {
                name: 'Должность',
              },
              {
                name: 'Физ лицо',
              },
            ],
          },

          {
            title: 'Роли организации в заказах',
            data: [
              {
                name: 'Заказчик',
                value: 'Формспецстрой-R1',
              },
              {
                name: 'Исполнитель',
                value: '43543-R2',
              },
            ],
            possibleToCreate: [
              {
                name: 'Роль',
                possibleValues: [
                  'я тут главная роль',
                  'а я роль чуть поменьше',
                  'моя роль - быть ролью',
                  'не доскажете, где здесб библиотека? а то мне нужно растянуть этот текст, чтобы он был очень длинным для теста...',
                ],
              },
              {
                name: 'Номер заказа',
              },
            ],
          },

          {
            title: 'Юридический адрес',
            data: [
              {
                name: 'Область',
                value: 'Московская',
              },
              {
                name: 'Город',
                value: 'Москва',
              },
              {
                name: 'Улица',
                value: 'Строителей',
              },
              {
                name: 'Дом',
                value: '14',
              },
            ],
            possibleToCreate: [
              {
                name: 'Офис',
              },
            ],
          },

          {
            title: 'Подписант от лица организации',
            data: [
              {
                name: 'Должность',
                value: 'Генеральный директор',
              },
              {
                name: 'Основание',
                value: 'Устав',
              },
            ],
            possibleToCreate: [
              {
                name: 'Дата вступления',
              },
            ],
          },
        ],
      },
      {
        type: 'company',
        phone2: '8 912 241 00 10',
        phone1: '8 912 123 45 67',
        email: 'example@mail.com',
        telegram: '8 912 123 45 67',
        id: '2872376',
        status: 'Активный',
        companyType: 'ООО',
        name: 'Никельтехнострой',
        fieldsInfo: [
          {
            title: 'Сотрудники организации',
            data: [
              {
                name: 'Ген директор',
                value: 'Иванов Сергей Петрович',
              },
              {
                name: 'Главбух',
                value: 'Шабаева Мария Петровна',
              },
              {
                name: 'Менеджер',
                value: 'Зубов Алексей Николаевич',
              },
            ],
            possibleToCreate: [
              {
                name: 'Должность',
              },
              {
                name: 'Физ лицо',
              },
            ],
          },

          {
            title: 'Роли организации в заказах',
            data: [
              {
                name: 'Заказчик',
                value: 'Никельтехнострой-R1',
              },
              {
                name: 'Исполнитель',
                value: '43543-R2',
              },
            ],
            possibleToCreate: [
              {
                name: 'Роль',
                possibleValues: [
                  'я тут главная роль',
                  'а я роль чуть поменьше',
                  'моя роль - быть ролью',
                  'не доскажете, где здесб библиотека? а то мне нужно растянуть этот текст, чтобы он был очень длинным для теста...',
                ],
              },
              {
                name: 'Номер заказа',
              },
            ],
          },

          {
            title: 'Юридический адрес',
            data: [
              {
                name: 'Область',
                value: 'Московская',
              },
              {
                name: 'Город',
                value: 'Москва',
              },
              {
                name: 'Улица',
                value: 'Строителей',
              },
              {
                name: 'Дом',
                value: '14',
              },
            ],
            possibleToCreate: [
              {
                name: 'Офис',
              },
            ],
          },

          {
            title: 'Подписант от лица организации',
            data: [
              {
                name: 'Должность',
                value: 'Генеральный директор',
              },
              {
                name: 'Основание',
                value: 'Устав',
              },
            ],
            possibleToCreate: [
              {
                name: 'Дата вступления',
              },
            ],
          },
        ],
      },
      {
        type: 'company',
        phone2: '8 912 241 00 10',
        phone1: '8 912 123 45 67',
        email: 'example@mail.com',
        telegram: '8 912 123 45 67',
        id: '4561606',
        status: 'Активный',
        companyType: 'ООО',
        name: 'Формпресстрой',
        fieldsInfo: [
          {
            title: 'Сотрудники организации',
            data: [
              {
                name: 'Ген директор',
                value: 'Иванов Сергей Петрович',
              },
              {
                name: 'Главбух',
                value: 'Шабаева Мария Петровна',
              },
              {
                name: 'Менеджер',
                value: 'Зубов Алексей Николаевич',
              },
            ],
            possibleToCreate: [
              {
                name: 'Должность',
              },
              {
                name: 'Физ лицо',
              },
            ],
          },

          {
            title: 'Роли организации в заказах',
            data: [
              {
                name: 'Заказчик',
                value: 'Формпресстрой-R1',
              },
              {
                name: 'Исполнитель',
                value: '43543-R2',
              },
            ],
            possibleToCreate: [
              {
                name: 'Роль',
                possibleValues: [
                  'я тут главная роль',
                  'а я роль чуть поменьше',
                  'моя роль - быть ролью',
                  'не доскажете, где здесб библиотека? а то мне нужно растянуть этот текст, чтобы он был очень длинным для теста...',
                ],
              },
              {
                name: 'Номер заказа',
              },
            ],
          },

          {
            title: 'Юридический адрес',
            data: [
              {
                name: 'Область',
                value: 'Московская',
              },
              {
                name: 'Город',
                value: 'Москва',
              },
              {
                name: 'Улица',
                value: 'Строителей',
              },
              {
                name: 'Дом',
                value: '14',
              },
            ],
            possibleToCreate: [
              {
                name: 'Офис',
              },
            ],
          },

          {
            title: 'Подписант от лица организации',
            data: [
              {
                name: 'Должность',
                value: 'Генеральный директор',
              },
              {
                name: 'Основание',
                value: 'Устав',
              },
            ],
            possibleToCreate: [
              {
                name: 'Дата вступления',
              },
            ],
          },
        ],
      },
      {
        type: 'company',
        phone2: '8 912 241 00 10',
        phone1: '8 912 123 45 67',
        email: 'example@mail.com',
        telegram: '8 912 123 45 67',
        id: '5419853',
        status: 'Активный',
        companyType: 'ООО',
        name: 'Транстехсервис',
        fieldsInfo: [
          {
            title: 'Сотрудники организации',
            data: [
              {
                name: 'Ген директор',
                value: 'Иванов Сергей Петрович',
              },
              {
                name: 'Главбух',
                value: 'Шабаева Мария Петровна',
              },
              {
                name: 'Менеджер',
                value: 'Зубов Алексей Николаевич',
              },
            ],
            possibleToCreate: [
              {
                name: 'Должность',
              },
              {
                name: 'Физ лицо',
              },
            ],
          },

          {
            title: 'Роли организации в заказах',
            data: [
              {
                name: 'Заказчик',
                value: 'Транстехсервис-R1',
              },
              {
                name: 'Исполнитель',
                value: '43543-R2',
              },
            ],
            possibleToCreate: [
              {
                name: 'Роль',
                possibleValues: [
                  'я тут главная роль',
                  'а я роль чуть поменьше',
                  'моя роль - быть ролью',
                  'не доскажете, где здесб библиотека? а то мне нужно растянуть этот текст, чтобы он был очень длинным для теста...',
                ],
              },
              {
                name: 'Номер заказа',
              },
            ],
          },

          {
            title: 'Юридический адрес',
            data: [
              {
                name: 'Область',
                value: 'Московская',
              },
              {
                name: 'Город',
                value: 'Москва',
              },
              {
                name: 'Улица',
                value: 'Строителей',
              },
              {
                name: 'Дом',
                value: '14',
              },
            ],
            possibleToCreate: [
              {
                name: 'Офис',
              },
            ],
          },

          {
            title: 'Подписант от лица организации',
            data: [
              {
                name: 'Должность',
                value: 'Генеральный директор',
              },
              {
                name: 'Основание',
                value: 'Устав',
              },
            ],
            possibleToCreate: [
              {
                name: 'Дата вступления',
              },
            ],
          },
        ],
      },
      {
        type: 'company',
        phone2: '8 912 241 00 10',
        phone1: '8 912 123 45 67',
        email: 'example@mail.com',
        telegram: '8 912 123 45 67',
        id: '9571180',
        status: 'Активный',
        companyType: 'ООО',
        name: 'Госстройпро',
        fieldsInfo: [
          {
            title: 'Сотрудники организации',
            data: [
              {
                name: 'Ген директор',
                value: 'Иванов Сергей Петрович',
              },
              {
                name: 'Главбух',
                value: 'Шабаева Мария Петровна',
              },
              {
                name: 'Менеджер',
                value: 'Зубов Алексей Николаевич',
              },
            ],
            possibleToCreate: [
              {
                name: 'Должность',
              },
              {
                name: 'Физ лицо',
              },
            ],
          },

          {
            title: 'Роли организации в заказах',
            data: [
              {
                name: 'Заказчик',
                value: 'Госстройпро-R1',
              },
              {
                name: 'Исполнитель',
                value: '43543-R2',
              },
            ],
            possibleToCreate: [
              {
                name: 'Роль',
                possibleValues: [
                  'я тут главная роль',
                  'а я роль чуть поменьше',
                  'моя роль - быть ролью',
                  'не доскажете, где здесб библиотека? а то мне нужно растянуть этот текст, чтобы он был очень длинным для теста...',
                ],
              },
              {
                name: 'Номер заказа',
              },
            ],
          },

          {
            title: 'Юридический адрес',
            data: [
              {
                name: 'Область',
                value: 'Московская',
              },
              {
                name: 'Город',
                value: 'Москва',
              },
              {
                name: 'Улица',
                value: 'Строителей',
              },
              {
                name: 'Дом',
                value: '14',
              },
            ],
            possibleToCreate: [
              {
                name: 'Офис',
              },
            ],
          },

          {
            title: 'Подписант от лица организации',
            data: [
              {
                name: 'Должность',
                value: 'Генеральный директор',
              },
              {
                name: 'Основание',
                value: 'Устав',
              },
            ],
            possibleToCreate: [
              {
                name: 'Дата вступления',
              },
            ],
          },
        ],
      },
      {
        type: 'company',
        phone2: '8 912 241 00 10',
        phone1: '8 912 123 45 67',
        email: 'example@mail.com',
        telegram: '8 912 123 45 67',
        id: '4250844',
        status: 'Активный',
        companyType: 'ООО',
        name: 'Градстройтех',
        fieldsInfo: [
          {
            title: 'Сотрудники организации',
            data: [
              {
                name: 'Ген директор',
                value: 'Иванов Сергей Петрович',
              },
              {
                name: 'Главбух',
                value: 'Шабаева Мария Петровна',
              },
              {
                name: 'Менеджер',
                value: 'Зубов Алексей Николаевич',
              },
            ],
            possibleToCreate: [
              {
                name: 'Должность',
              },
              {
                name: 'Физ лицо',
              },
            ],
          },

          {
            title: 'Роли организации в заказах',
            data: [
              {
                name: 'Заказчик',
                value: 'Градстройтех-R1',
              },
              {
                name: 'Исполнитель',
                value: '43543-R2',
              },
            ],
            possibleToCreate: [
              {
                name: 'Роль',
                possibleValues: [
                  'я тут главная роль',
                  'а я роль чуть поменьше',
                  'моя роль - быть ролью',
                  'не доскажете, где здесб библиотека? а то мне нужно растянуть этот текст, чтобы он был очень длинным для теста...',
                ],
              },
              {
                name: 'Номер заказа',
              },
            ],
          },

          {
            title: 'Юридический адрес',
            data: [
              {
                name: 'Область',
                value: 'Московская',
              },
              {
                name: 'Город',
                value: 'Москва',
              },
              {
                name: 'Улица',
                value: 'Строителей',
              },
              {
                name: 'Дом',
                value: '14',
              },
            ],
            possibleToCreate: [
              {
                name: 'Офис',
              },
            ],
          },

          {
            title: 'Подписант от лица организации',
            data: [
              {
                name: 'Должность',
                value: 'Генеральный директор',
              },
              {
                name: 'Основание',
                value: 'Устав',
              },
            ],
            possibleToCreate: [
              {
                name: 'Дата вступления',
              },
            ],
          },
        ],
      },
      {
        type: 'company',
        phone2: '8 912 241 00 10',
        phone1: '8 912 123 45 67',
        email: 'example@mail.com',
        telegram: '8 912 123 45 67',
        id: '2236738',
        status: 'Активный',
        companyType: 'ООО',
        name: 'Сервисбилдинг',
        fieldsInfo: [
          {
            title: 'Сотрудники организации',
            data: [
              {
                name: 'Ген директор',
                value: 'Иванов Сергей Петрович',
              },
              {
                name: 'Главбух',
                value: 'Шабаева Мария Петровна',
              },
              {
                name: 'Менеджер',
                value: 'Зубов Алексей Николаевич',
              },
            ],
            possibleToCreate: [
              {
                name: 'Должность',
              },
              {
                name: 'Физ лицо',
              },
            ],
          },

          {
            title: 'Роли организации в заказах',
            data: [
              {
                name: 'Заказчик',
                value: 'Сервисбилдинг-R1',
              },
              {
                name: 'Исполнитель',
                value: '43543-R2',
              },
            ],
            possibleToCreate: [
              {
                name: 'Роль',
                possibleValues: [
                  'я тут главная роль',
                  'а я роль чуть поменьше',
                  'моя роль - быть ролью',
                  'не доскажете, где здесб библиотека? а то мне нужно растянуть этот текст, чтобы он был очень длинным для теста...',
                ],
              },
              {
                name: 'Номер заказа',
              },
            ],
          },

          {
            title: 'Юридический адрес',
            data: [
              {
                name: 'Область',
                value: 'Московская',
              },
              {
                name: 'Город',
                value: 'Москва',
              },
              {
                name: 'Улица',
                value: 'Строителей',
              },
              {
                name: 'Дом',
                value: '14',
              },
            ],
            possibleToCreate: [
              {
                name: 'Офис',
              },
            ],
          },

          {
            title: 'Подписант от лица организации',
            data: [
              {
                name: 'Должность',
                value: 'Генеральный директор',
              },
              {
                name: 'Основание',
                value: 'Устав',
              },
            ],
            possibleToCreate: [
              {
                name: 'Дата вступления',
              },
            ],
          },
        ],
      },
      {
        type: 'company',
        phone2: '8 912 241 00 10',
        phone1: '8 912 123 45 67',
        email: 'example@mail.com',
        telegram: '8 912 123 45 67',
        id: '1168431',
        status: 'Активный',
        companyType: 'ООО',
        name: 'Транскорпстрой',
        fieldsInfo: [
          {
            title: 'Сотрудники организации',
            data: [
              {
                name: 'Ген директор',
                value: 'Иванов Сергей Петрович',
              },
              {
                name: 'Главбух',
                value: 'Шабаева Мария Петровна',
              },
              {
                name: 'Менеджер',
                value: 'Зубов Алексей Николаевич',
              },
            ],
            possibleToCreate: [
              {
                name: 'Должность',
              },
              {
                name: 'Физ лицо',
              },
            ],
          },

          {
            title: 'Роли организации в заказах',
            data: [
              {
                name: 'Заказчик',
                value: 'Транскорпстрой-R1',
              },
              {
                name: 'Исполнитель',
                value: '43543-R2',
              },
            ],
            possibleToCreate: [
              {
                name: 'Роль',
                possibleValues: [
                  'я тут главная роль',
                  'а я роль чуть поменьше',
                  'моя роль - быть ролью',
                  'не доскажете, где здесб библиотека? а то мне нужно растянуть этот текст, чтобы он был очень длинным для теста...',
                ],
              },
              {
                name: 'Номер заказа',
              },
            ],
          },

          {
            title: 'Юридический адрес',
            data: [
              {
                name: 'Область',
                value: 'Московская',
              },
              {
                name: 'Город',
                value: 'Москва',
              },
              {
                name: 'Улица',
                value: 'Строителей',
              },
              {
                name: 'Дом',
                value: '14',
              },
            ],
            possibleToCreate: [
              {
                name: 'Офис',
              },
            ],
          },

          {
            title: 'Подписант от лица организации',
            data: [
              {
                name: 'Должность',
                value: 'Генеральный директор',
              },
              {
                name: 'Основание',
                value: 'Устав',
              },
            ],
            possibleToCreate: [
              {
                name: 'Дата вступления',
              },
            ],
          },
        ],
      },
    ],
  },
]

export default peoplesAndCompanies
